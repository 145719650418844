<template>
  <div id="convert_to_order_modal" class="modal fade" tabindex="-1" aria-hidden="true" style="display: none;">
    <div class="modal-dialog">
      <div class="modal-content border-0">
        <div class="modal-header border-bottom p-3">
          <h5 class="modal-title">
            Convert to Order
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

        <form action="" @submit.prevent="createOrder()" class="p-4 pt-3 text-dark">
          <div class="row">
            <div class="col-12">
              <b-alert show variant="warning" role="alert">
                We get all the <strong>data</strong> required for order from <strong>this application </strong><br>
                It is <strong>your responsibility</strong> to make sure <strong>everything is correct</strong> with your
                application !
              </b-alert>
            </div>
            <div class="col-lg-6">
              <div class="mb-3">
                <label for="container_type" class="form-label">Order type</label>
                <select v-model="order_type" class="form-select">
                  <option value="import">Import</option>
                  <option value="export">Export</option>
                  <option value="transit">Transit</option>
                </select>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="mb-3">
                <label for="company" class="form-label">Company</label>
                <SelectCompany @onSelect="company = $event"/>
              </div>
            </div>
            <div class="col-6">
              <div class="mb-3">
                <label for="agreed_rate" class="form-label">Agreed rate</label>
                <input v-model="agreed_rate" type="text" class="form-control" placeholder="Agreed rate"
                       id="agreed_rate">
              </div>
            </div>
            <div class="col-6">
              <div class="mb-3">
                <label for="total_expense" class="form-label">Total expense</label>
                <input v-model="total_expense" type="text" class="form-control" placeholder="Total expense"
                       id="total_expense">
              </div>
            </div>
            <div class="col-12 mt-3">
              <b-button v-if="!is_creating_order" type="submit" variant="success"
                        class="waves-effect waves-light w-100">Convert to Order
              </b-button>
              <div v-else class="hstack flex-wrap gap-2 mb-3 mb-lg-0">
                <button type="button" class="btn btn-success btn-load w-100">
                  <span class="d-flex align-items-center justify-content-center">
                    <span class="spinner-border flex-shrink-0" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </span>
                    <span class="ms-2">
                      Converting...
                    </span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import SelectCompany from "@/components/custom/SelectCompany.vue";
import axios from "axios";

export default {
  name: "convert_to_order_modal",
  props: {
    loadingType: {
      type: String,
      default: () => '',
    },
    applicationId: {
      type: Number,
      default: () => null,
    },
  },
  data() {
    return {
      application_id: null,
      loading_type: '',
      order_type: '',
      company: null,
      agreed_rate: null,
      total_expense: null,
      is_creating_order: false,
    }
  },
  methods: {
    async createOrder() {
      this.is_creating_order = true;
      let response = await axios.post(`/code/application/create_order/${this.$route.params.id}/`, {
        order_type: this.order_type,
        company_id: this.company.value,
        agreed_rate: this.agreed_rate,
        total_expanses: this.total_expense,
        customer: 1
      });
      this.is_creating_order = false;
      if (response.status === 201) {
        await Swal.fire({
          title: 'Order created successfully',
          html: `You can view your order <a href="${this.getCreatedOrderType}/${response.data.order_number}">here</a>`,
          icon: 'success',
          confirmButtonText: 'Ok'
        })
      } else {
        await Swal.fire({
          title: 'Error',
          text: response.data.message,
          icon: 'error',
          confirmButtonText: 'Ok'
        })
      }
    }
  },
  components: {
    SelectCompany,
  },
  computed: {
    getCreatedOrderType() {
      return this.loading_type === 'container' ? '/orders/container/view' : this.loading_type === 'wagon' ? '/orders/wagon/view' : '/orders/empty-wagon/view';
    }
  },
  watch: {
    loadingType(val) {
      this.loading_type = val;
    },
    applicationId(val) {
      this.application_id = val;
    }
  },
  mounted() {
    this.loading_type = this.loadingType;
    this.application_id = this.applicationId;
  }
}
</script>

<style scoped>

</style>